<template>
  <v-app>
    <span class="bg"></span>
    <div class="d-flex justify-end mr-4">
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="my-menu"
        open-on-hover
      >
        <template v-slot:activator="{ on }">
          <v-btn icon color="pink" dark v-on="on">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in states" :key="item">
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <section id="login" class="login">
      <div class="container">
        <div class="login-content">
          <slot />
        </div>
      </div>
    </section>
    <section id="footer" class="footer">
      <v-footer class="footer-container justify-center">
        <span class="copy">
          &copy; 2008 - {{ new Date().getFullYear() }} Powered by&nbsp;
          <a href="https://web.mynube.com/" target="_blank" class="copyLink">
            mynube</a
          >
        </span>
      </v-footer>
    </section>
  </v-app>
</template>

<script>
export default {
  name: "LoginLayout",
  data: () => ({
    e1: "Español",
    states: ["Español", "English"],
  }),
};
</script>

<style lang="scss" scoped src="@/assets/css/login-home.scss"></style>

<style scoped>
.custom .v-input__slot::before,
.custom .v-input__slot::after {
  border: none !important;
}

.v-select__selection--comma {
  margin: 7px 4px 7px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: right;
}
.my-menu {
  margin-top: 40px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  right: 10px;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}
.copy {
  font-size: 0.6875em;
}
.copyLink {
  text-decoration: none;
}
</style>
